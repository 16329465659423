import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import "./careersstyle.css";
import "../home/homestyle.css";

const initialState = {
        fname: '',
        lname: '',
        gender: '',
        maritialStatus: '',
        country: '',
        contact: '',
        email: '',
        dob: '',
        // photo: '',
        resumeLink: '',
        // resume: '',
        qualification: '',
        course: '',
        passingYear: '',
        address: '',
        city: '',
        pin: '',
        web: false,
        mbapp: false,
        mrkt: false,
        webgraph: false,
        cntnt: false,
        work: '',
        describeDomain: '',
        project1: '',
        project2: '',
        project3: '',
        project4: '',
        project5: '',
        refCode: '',
        declaration: '',
        referredBy: '',
};


const FreelancerCareerForm = () =>{
    // const [{ fname, lname, gender, country, contact, email, photo, resume, dob, qualification, course, passingYear, address, city, pin, web, mbapp, mrkt, webgraph, cntnt, work, describeDomain, project1, project2, project3, project4, project5, refCode, referredBy}, setState] = useState(initialState);
    const [state, setState] = useState(initialState);
    const { fname, lname, gender, maritalStatus, country, contact, email, dob, resumeLink, qualification, course, passingYear, address, city, pin, web, mbapp, mrkt, webgraph, cntnt, work, describeDomain, project1, project2, project3, project4, project5, refCode, declaration, referredBy} = state;
    // const { fname, lname, gender, maritalStatus, country, contact, email, dob, photo, resumeLink, qualification, course, passingYear, address, city, pin, web, mbapp, mrkt, webgraph, cntnt, work, describeDomain, project1, project2, project3, project4, project5, refCode, declaration, referredBy} = state;
    // const [formData, setFormData] = useState(initialState);
    // const [fname, setfName] = useState("");
    // const [lname, setlName] = useState("");
    // const [gender, setGender] = useState('');
    // const [country, setCountry] = useState("");
    // const [contact, setContact] = useState("");
    // const [email, setEmail] = useState("");
    // const [photo, setPhoto] = useState("");
    // const [resume, setResume] = useState("");
    // const [dob, setDOB] = useState('');
    // const [qualification, setQualification] = useState('');
    // const [course, setCourse] = useState('');
    // const [passingYear, setPassingYear] = useState('');
    // const [address, setAddress] = useState('');
    // const [city, setCity] = useState('');
    // const [pin, setPin] = useState('');
    // const [web, setWeb] = useState('');
    // const [mbapp, setMbapp] = useState('');
    // const [mrkt, setMrkt] = useState('');
    // const [webgraph, setWebgraph] = useState('');
    // const [cntnt, setCntnt] = useState('');
    // const [describeDomain, setDescribeDomain] = useState('');
    // const [project1, setProject1] = useState('');
    // const [project2, setProject2] = useState('');
    // const [project3, setProject3] = useState('');
    // const [project4, setProject4] = useState('');
    // const [project5, setProject5] = useState('');
    // const [refCode, setRefCode] = useState('');
    const [genderMsg, setGenderMsg] = useState('');
    const [maritalStatusMsg, setMaritalStatusMsg] = useState('');
    const [countryMsg, setCountryMsg] = useState('');
    // const [photoMsg, setPhotoMsg] = useState('');
    const [resumeMsg, setResumeMsg] = useState('');
    const [DOBMsg, setDOBMsg] = useState('');
    const [qualificationMsg, setQualificationMsg] = useState('');
    const [contactMsg, setContactMsg] = useState('');
    const [emailMsg, setEmailMsg] = useState('');
    const [pinMsg, setPinMsg] = useState('');
    const [workMsg, setWorkMsg] = useState('');
    const [passingYearMsg, setPassingYearMsg] = useState('');
    const [applicationSubmitionMsg, setApplicationSubmitionMsg] = useState('');

    // Use refs to access the input elements
    const fnameRef = useRef("");
    const lnameRef = useRef("");
    const genderRef = useRef(null);
    const maritalStatusRef = useRef(null);
    const countryRef = useRef("");
    const contactRef = useRef("");
    const emailRef = useRef("");
    // const photoRef = useRef(null);
    const resumeRef = useRef(null);
    const dobRef = useRef("");
    const qualificationRef = useRef("");
    const courseRef = useRef("");
    const passingYearRef = useRef("");
    const addressRef = useRef("");
    const cityRef = useRef("");
    const pinRef = useRef("");
    const workRef = useRef("");
    const declarationRef = useRef("");
    

    const handleChange = (e) => {
        setApplicationSubmitionMsg('');
        const { name, value, files, type, checked } = e.target;
        if (files) {
            // if (files.length === 0) {
            //     setState(prevState => ({
            //         ...prevState,
            //         [name]: ''
            //     }));
            //     setPhotoMsg('Plese select your photo');
            //     photoRef.current.focus();
            // } else {
            //     validateFileSize(name, files[0]);
            //     setState(prevState => ({
            //         ...prevState,
            //         [name]: files[0]
            //     }));
            // }
        } else if (type === 'radio' && name === 'gender') {
            setState((prevState) => ({
                ...prevState,
                gender: value,
            }));
            setGenderMsg(''); // Clear genderMsg when gender value changes
        } else if (type === 'radio' && name === 'maritalStatus') {
            setState((prevState) => ({
                ...prevState,
                maritalStatus: value,
            }));
            setMaritalStatusMsg(''); // Clear maritalStatusMsg when maritalStatus value changes
        } else if (name === 'country') {
            setState((prevState) => ({
                ...prevState,
                country: value,
                
            }));
            if (value === ''){
                setCountryMsg('Please select your country');  // Setting countryMsg when country value change to null
                countryRef.current.focus();
            } else {
                setCountryMsg(''); // Clear countryMsg when country value changes
            }
        } else if (name === 'contact') {
            setState((prevState) => ({
                ...prevState,
                contact: value,
                
            }));
            if (value === ''){
                setContactMsg('Please provide your contact number');  // Setting contactMsg when contact value change to null
                contactRef.current.focus();
            } else {
                setContactMsg(''); // Clear contactMsg when contact value changes
            }
        } else if (name === 'email') {
            setState((prevState) => ({
                ...prevState,
                email: value,
                
            }));
            if (value === ''){
                setEmailMsg('Please provide your email ID');  // Setting emailMsg when email value change to null
                emailRef.current.focus();
            } else {
                setEmailMsg(''); // Clear emailMsg when email value changes
            }
        } else if (name === 'dob') {
            setState((prevState) => ({
                ...prevState,
                dob: value,
                
            }));
            if (value === ''){
                setDOBMsg('Please select your Date of Birth');  // Setting DOBMsg when dob value change to null
                dobRef.current.focus();
            } else {
                setDOBMsg(''); // Clear DOBMsg when dob value changes
            }
        } else if (name === 'resumeLink') {
            setState((prevState) => ({
                ...prevState,
                resumeLink: value,
                
            }));
            if (value === ''){
                setResumeMsg('Please provide your resume link');  // Setting resumeMsg when resumeLink value change to null
                resumeRef.current.focus();
            } else {
                setResumeMsg(''); // Clear resumeMsg when resumeLink value changes
            }
        } else if (name === 'qualification') {
            setState((prevState) => ({
                ...prevState,
                qualification: value,
                
            }));
            if (value === ''){
                setQualificationMsg('Please provide your qualification');  // Setting qualificationMsg when qualification value change to null
                qualificationRef.current.focus();
            } else {
                setQualificationMsg(''); // Clear qualificationMsg when qualification value changes
            }
        } else if (name === 'passingYear') {
            setState((prevState) => ({
                ...prevState,
                passingYear: value,
                
            }));
            if (value === ''){
                setPassingYearMsg('Please provide your passing year');  // Setting passingYearMsg when passingYear value change to null
                passingYearRef.current.focus();
            } else {
                setPassingYearMsg(''); // Clear passingYearMsg when passingYear value changes
            }
        } else if (name === 'pin') {
            setState((prevState) => ({
                ...prevState,
                pin: value,
                
            }));
            if (value === ''){
                setPinMsg('Please provide your pin code');  // Setting pinMsg when pin value change to null
                pinRef.current.focus();
            } else {
                setPinMsg(''); // Clear pinMsg when pin value changes
            }
        } else if (type === 'checkbox' && ['web', 'mbapp', 'mrkt', 'webgraph', 'cntnt'].includes(name)) {
            setState(prevState => ({
                ...prevState,
                [name]: checked,
            }));

            const newWorkState = {
                ...state,
                [name]: checked,
            };

            const isAnyChecked = Object.keys(newWorkState).some(key => ['web', 'mbapp', 'mrkt', 'webgraph', 'cntnt'].includes(key) && newWorkState[key]);

            if (isAnyChecked === '') {
                setWorkMsg('Please select your domain of work');
                workRef.current.focus();
            } else {
                setWorkMsg('');
            }
        } else if (type === 'checkbox' && ['declaration'].includes(name)) {
            setState(prevState => ({
                ...prevState,
                [name]: checked,
            }));

            // const newDeclarationState = {
            //     ...state,
            //     [name]: checked,
            // };

            // const isAnyChecked = Object.keys(newDeclarationState).some(key => ['declaration'].includes(key) && newDeclarationState[key]);

            // if (isAnyChecked === '') {
            //     setApplicationSubmitionMsg('Please read and accept the terms and condition');
            //     declarationRef.current.focus();
            // } else {
            //     setApplicationSubmitionMsg('');
            // }

        } else {
            setState(prevState => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    // PHOTO FILE VALIDATION - photo
    // const validateFileSize = (name, file) => {
    //     const maxSizeKB = 35; // 35KB for photo
    //     if (file.size > maxSizeKB * 1024) {
    //       setPhotoMsg(`Photo size exceeds ${maxSizeKB}KB limit`);
    //       photoRef.current.focus();
    //     } else {
    //       setPhotoMsg('');
    //     }
    // };

    // const validateFileSize = (name, file) => {
    //     const maxSizeKB = name === 'photo' ? 35 : 100; // 35KB for photo, 100KB for resume
    //     if (file.size > maxSizeKB * 1024) {
    //       if (name === 'photo') {
    //         setPhotoMsg(`Photo size exceeds ${maxSizeKB}KB limit.`);
    //       } else {
    //         setResumeMsg(`Resume size exceeds ${maxSizeKB}KB limit.`);
    //       }
    //     } else {
    //       if (name === 'photo') {
    //         setPhotoMsg('');
    //       } else {
    //         setResumeMsg('');
    //       }
    //     }
    //   };



    // PHOTO FILE BASE 64 - photo
    // const readFileAsBase64 = (file) => {
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.onload = () => resolve(reader.result.split(',')[1]);
    //       reader.onerror = error => reject(error);
    //       reader.readAsDataURL(file);
    //     });
    // };

    
    // Function to programmatically change the value of "referredBy"
    const updateReferredBy = (newReferredBy) => {
        setState((prevState) => ({
        ...prevState,
        referredBy: newReferredBy,
        }));
    };


    useEffect(() => {

        let work = '';

        const roles = [];
        if (web) roles.push('Web Developer');
        if (mbapp) roles.push('Mobile Application Developer');
        if (mrkt) roles.push('Digital Marketer and SEO');
        if (webgraph) roles.push('Web & Graphic Designer');
        if (cntnt) roles.push('Content Writer');

        work = roles.join(', ');

        setState((prevState) => ({ ...prevState, work }));

        if ((refCode === '006124') || (refCode === '06124') || (refCode === '6124')) {
          updateReferredBy('Amitendu Roy Chowdhury');
        } else if ((refCode === '007698') || (refCode === '07698') || (refCode === '7698')) {
          updateReferredBy('Sayantan Chakraborty');
        } else if ((refCode === '009013') || (refCode === '09013') || (refCode === '9013')) {
          updateReferredBy('Nagendra Nath Hembram');
        } else if ((refCode === '011111') || (refCode === '11111')) {
          updateReferredBy('Raju Brahma');
        } 
    }, [gender, country, dob, resumeLink, qualification, web, mbapp, mrkt, webgraph, cntnt, refCode]);
    // }, [gender, country, dob, photo, resumeLink, qualification, web, mbapp, mrkt, webgraph, cntnt, refCode]);

    const clearState = () => setState({ ...initialState });

    const freelanceApplicationSubmission = async (e) => {
        e.preventDefault();


        setApplicationSubmitionMsg('');
        
        if (!fname) {
            fnameRef.current.focus();
        } else if (!lname) {
            lnameRef.current.focus();
        } else if (!gender) {
            setGenderMsg('Please select your gender');
            genderRef.current.focus();
        } else if (!maritalStatus) {
            setMaritalStatusMsg('Please select your marital status');
            maritalStatusRef.current.focus();
        } else if (!country) {
            setCountryMsg('Please select your country');
            countryRef.current.focus();
        } else if (!contact) {
            contactRef.current.focus();
        } else if (!email) {
            emailRef.current.focus();
        } else if (!dob) {
            setDOBMsg('Please select your Date of Birth');
            dobRef.current.focus();
        // } else if (!photo) {
        //     setPhotoMsg('Please select your photo');
        //     photoRef.current.focus();
        } else if (!resumeLink) {
            setResumeMsg('Please provide your resume link');
            resumeRef.current.focus();
        } else if (!qualification) {
            setQualificationMsg('Please select your qualification');
            qualificationRef.current.focus();
        } else if (!course) {
            courseRef.current.focus();
        } else if (!passingYear) {
            setPassingYearMsg('Please provide your passing year');
            passingYearRef.current.focus();
        } else if (!address) {
            addressRef.current.focus();
        } else if (!city) {
            cityRef.current.focus();
        } else if (!pin) {
            setPinMsg('Please provide your pin code');
            pinRef.current.focus();
        } else if (!web && !mbapp && !mrkt && !webgraph && !cntnt) {
            setWorkMsg('Please select your domain of work');
            workRef.current.focus();
        }
        else{
            // Pin code verification
            let finalPin = false;
            if (!isNaN(pin)) {
                if (pin.length === 6) {
                    setPinMsg('');
                    finalPin = true;
                }  else {
                    setPinMsg("Use the standard 6 digit area pin code number format, such as 444444");
                    pinRef.current.focus();
                }
            } else if (pin.includes(' ')) {
                setPinMsg("Space is not allowed, use the standard 6 digit area pin code number format, such as 444444");
                pinRef.current.focus();
            } else {
                setPinMsg("Use the standard 6 digit area pin code number format, such as 444444");
                pinRef.current.focus();
            }


            // Passing Year verification
            let finalPassingYear = false;
            if (!isNaN(passingYear)) {
                if (passingYear.length === 4) {
                    setPassingYearMsg('');
                    finalPassingYear = true;
                }  else {
                    setPassingYearMsg("Use the standard YYYY format");
                    passingYearRef.current.focus();
                }
            } else if (pin.includes(' ')) {
                setPassingYearMsg("Space is not allowed, use the standard YYYY format");
                passingYearRef.current.focus();
            } else {
                setPassingYearMsg("Use the standard YYYY format");
                passingYearRef.current.focus();
            }

            
            // Photo verification - photo
            // if (photoMsg) {
            //     photoRef.current.focus();
            // }


            // Email verification
            let finalEmail = false;
            let splChr = 0;
            let atTheRate = 0;
            let str = email;
            let atTheRatePosition = 0;
            let dotCount = 0;

            for (let i = 0; i < str.length; ++i) {
                let ch = str.charCodeAt(i);
                if (
                    !(ch >= 65 && ch <= 90) && // A-Z
                    !(ch >= 97 && ch <= 122) && // a-z
                    !(ch >= 48 && ch <= 57) && // 0-9
                    !(ch === 64) && // @
                    !(ch === 46) && // .
                    !(ch === 95) && // _
                    !(ch === 45) // -
                ) {
                    splChr = 1;
                }
            }

            for (let j = 0; j < str.length; ++j) {
                let ch = str[j];
                if (ch === "@") {
                    atTheRate += 1;
                    atTheRatePosition = j;
                }
            }

            if (atTheRate === 1) {
                for (let j = atTheRatePosition + 2; j < str.length; ++j) {
                    let ch = str[j];
                    if (ch === ".") {
                        dotCount += 1;
                    }
                }
            }

            if ((email.includes('@')) && (email.includes('.')) && (!email.includes(' '))) {
                if ((splChr === 0) && (atTheRate === 1) && (dotCount === 1)) {
                    setEmailMsg('');
                    finalEmail = true;
                } else {
                    setEmailMsg("Use the standard username format, such as name@example.com");
                    emailRef.current.focus();
                }
            } else if (contact.includes(' ')) {
                setEmailMsg("Space is not allowed, use the standard username format, such as name@example.com");
                emailRef.current.focus();
            } else {
                setEmailMsg("Use the standard username format, such as name@example.com");
                emailRef.current.focus();
            }


            // Contact verification
            let finalContact = false;
            if (!isNaN(contact)) {
                if (contact.length === 10) {
                    setContactMsg('');
                    finalContact = true;
                } else {
                    setContactMsg("Use the standard 10 digit mobile number format, such as 9876543210");
                    contactRef.current.focus();
                }
            } else if (contact.includes(' ')) {
                setContactMsg("Space is not allowed, use the standard 10 digit mobile number format, such as 9876543210");
                contactRef.current.focus();
            } else {
                setContactMsg("Use the standard 10 digit mobile number format, such as 9876543210");
                contactRef.current.focus();
            }

            
            try {
                // const photoBase64 = await readFileAsBase64(photo);
                // const resumeBase64 = await readFileAsBase64(resume);

                // NOT NEEDED
                // const templateParamsBase64 = {
                //     photo_attachment: photoBase64,
                //     photo_filename: photo.name,
                //     resume_attachment: resumeBase64,
                //     resume_filename: resume.name,
                // }
            

                const templateParams = {
                    fname: fname,
                    lname: lname,
                    gender: gender,
                    maritalStatus: maritalStatus,
                    country: country,
                    contact: contact,
                    email: email,
                    dob: dob[8]+dob[9]+dob[7]+dob[5]+dob[6]+dob[4]+dob[0]+dob[1]+dob[2]+dob[3],
                    // photo_attachment: photoBase64,   // 40kb
                    // photo_filename: state.photo.name,
                    resumeLink: resumeLink,
                    // photo_attachment: photoBase64,   // 10kb
                    // photo_filename: photo,
                    // resume_attachment: resumeBase64, // 30kb
                    // resume_filename: resume.name,
                    qualification: qualification,
                    course: course,
                    passingYear: passingYear,
                    address: address,
                    city: city,
                    pin: pin,
                    work: work,
                    describeDomain: describeDomain,
                    project1: project1,
                    project2: project2,
                    project3: project3,
                    project4: project4,
                    project5: project5,
                    refCode: refCode,
                    referredBy: referredBy,
                };


                // if (finalContact && finalEmail && !photoMsg && finalPassingYear && finalPin) { 
                if (finalContact && finalEmail && finalPassingYear && finalPin) { 
                    
                    if (declaration) {
                        // if ((refCode === '009013') || (refCode === '007698') || (refCode === '006124') || (refCode === '')){   // by owner or by self
                        if ((refCode === '006124') || (refCode === '06124') || (refCode === '6124') || 
                        (refCode === '007698') || (refCode === '07698') || (refCode === '7698') || 
                        (refCode === '009013') || (refCode === '09013') || (refCode === '9013') || 
                        (refCode === '011111') || (refCode === '11111') ||
                        (refCode === '011112') || (refCode === '11112')) {                         // Only by owner
                            setApplicationSubmitionMsg('Please wait...');
                            await emailjs.send("service_gxyqsq9", "template_l4d9i6h", templateParams, "iuYel3K2CQSYsRMMp")
                            // console.log(templateParams);
                            // const response = await emailjs.send("service_55lna5m", "template_l4d9i6h", templateParams, "iuYel3K2CQSYsRMMp")
                            // const responseBase64 = await emailjs.send("service_55lna5m", "template_l4d9i6h", templateParamsBase64, "iuYel3K2CQSYsRMMp")
                            // console.log('SUCCESS!', response.status, response.text);
                            // console.log('SUCCESS! Base64', responseBase64.status, responseBase64.text);
                            setApplicationSubmitionMsg('Application submited successfully!');
                            clearState();
                            // alert('Email sent successfully!');
                        } else {
                            setApplicationSubmitionMsg("Sorry, there is no vacancy for this freelance post from your region at this moment. Please come back again later.");
                        }
                    } else {
                        setApplicationSubmitionMsg("Please read and accept the terms and conditions")
                    }
                } else {
                    setApplicationSubmitionMsg("Please enter your details properly");
                }
            } catch (error) {
                setApplicationSubmitionMsg('Sorry! Something went wrong')
                // console.error('FAILED...', error);
                // alert('Failed to send email.');
            }
            return false;  
        }
    }
    

    return(
        <div className="formBody">
            <div className="formHead">
                Fill your details as Freelancer
            </div>
            <div>
                <form id="contactForm" style={{marginTop: "50px"}} data-sb-form-api-token="API_TOKEN" onSubmit={{freelanceApplicationSubmission}}>
                    <div className="row align-items-stretch mb-5">

                        <div className="col-md-6">
                            {/* First Name input */}
                            <div className="form-group">
                                <input className="form-control" id="fName" type="text" name="fname" placeholder="First name*"  data-sb-validations="required" required value={fname} onChange={handleChange} ref={fnameRef} />
                            </div>
                        </div>
                        <div className="col-md-6 domain-message-div">
                            {/* Last Name input */}
                            <div className="form-group">
                                <input className="form-control" id="lName" type="text" name="lname" placeholder="Last name*"  data-sb-validations="required" required value={lname} onChange={handleChange} ref={lnameRef} />
                            </div>
                        </div>
                            
                            
                        <div>
                            {/* Gender */}
                            <div className="form-group freelanceItem freelanceSmallItem" >
                                {/* <p>Select your Gender:</p> */}
                                Gender*:{" "}
                                &nbsp;&nbsp;
                                <div className="genderAdjustment"></div>
                                <input 
                                    type="radio" 
                                    style={{accentColor: '#e34a50'}}
                                    id="transgender" 
                                    name="gender" 
                                    value="Transgender" 
                                    checked={gender === 'Transgender'}
                                    onChange={handleChange}
                                    ref={genderRef}
                                />
                                 <label for="transgender">Transgender</label>
                                &nbsp;&nbsp;
                                 <input 
                                    type="radio" 
                                    style={{accentColor: '#e34a50'}}
                                    id="male" 
                                    name="gender" 
                                    value="Male" 
                                    checked={gender === 'Male'}
                                    onChange={handleChange}
                                />
                                 <label for="male">Male</label>
                                &nbsp;&nbsp;
                                 <input 
                                    type="radio" 
                                    style={{accentColor: '#e34a50'}}
                                    id="female" 
                                    name="gender" 
                                    value="Female" 
                                    checked={gender === 'Female'}
                                    onChange={handleChange}
                                />
                                 <label for="female">Female</label>
                                &nbsp;&nbsp;
                            </div>
                            <div style={{textAlign: "left", marginLeft: "20px"}}>
                                <p style={{"color": "#bf0a30"}} id="genderMsg">{genderMsg}</p>
                            </div>
                            
                            {/* Marital status */}
                            <div className="form-group freelanceItem freelanceSmallItem" >
                                {/* <p>Select your Marital status:</p> */}
                                Marital status*:{" "}
                                &nbsp;&nbsp;
                                <div className="genderAdjustment"></div>
                                <input 
                                    type="radio" 
                                    style={{accentColor: '#e34a50'}}
                                    id="single" 
                                    name="maritalStatus" 
                                    value="Single" 
                                    checked={maritalStatus === 'Single'}
                                    onChange={handleChange}
                                    ref={maritalStatusRef}
                                />
                                 <label for="single">Single</label>
                                &nbsp;&nbsp;
                                 <input 
                                    type="radio" 
                                    style={{accentColor: '#e34a50'}}
                                    id="married" 
                                    name="maritalStatus" 
                                    value="Married" 
                                    checked={maritalStatus === 'Married'}
                                    onChange={handleChange}
                                />
                                 <label for="married">Married</label>
                                &nbsp;&nbsp;
                                 <input 
                                    type="radio" 
                                    style={{accentColor: '#e34a50'}}
                                    id="divorced" 
                                    name="maritalStatus" 
                                    value="Divorced" 
                                    checked={maritalStatus === 'Divorced'}
                                    onChange={handleChange}
                                />
                                 <label for="divorced">Divorced</label>
                                &nbsp;&nbsp;
                                 <input 
                                    type="radio" 
                                    style={{accentColor: '#e34a50'}}
                                    id="widowed" 
                                    name="maritalStatus" 
                                    value="Widowed" 
                                    checked={maritalStatus === 'Widowed'}
                                    onChange={handleChange}
                                />
                                 <label for="widowed">Widowed</label>
                            </div>
                            <div style={{textAlign: "left", marginLeft: "20px"}}>
                                <p style={{"color": "#bf0a30"}} id="maritalStatusMsg">{maritalStatusMsg}</p>
                            </div>

                            {/* Country selection*/}
                            <div className="form-group freelanceItem">
                                {/* <select id="country" data-sb-validations="required" required style={{"width": "100%", "height": "38px", "text-align": "left", "border-radius": "5px", "padding-left": "4%"}} value={country} onChange={handleChange}> */}
                                <select 
                                    id="country" 
                                    data-sb-validations="required" 
                                    required 
                                    style={{width: "100%", height: "38px", textAlign: "left", borderRadius: "5px", cursor: "pointer", paddingLeft: "0.5%"}} 
                                    name="country" 
                                    value={country} 
                                    onChange={handleChange} 
                                    ref={countryRef} 
                                >
                                    <option value="">Select Country*</option>
                                    <option value="India (+91)">India (+91)</option>
                                    <option value="Afghanistan (+93)">Afghanistan (+93)</option>
                                    <option value="Albania (+355)">Albania (+355)</option>
                                    <option value="Algeria (+213)">Algeria (+213)</option>
                                    <option value="American Samoa (+1-684)">American Samoa (+1-684)</option>
                                    <option value="Andorra (+376)">Andorra (+376)</option>
                                    <option value="Angola (+244)">Angola (+244)</option>
                                    <option value="Anguilla (+1-264)">Anguilla (+1-264)</option>
                                    <option value="Antigua and Barbuda (+1-268)">Antigua and Barbuda (+1-268)</option>
                                    <option value="Argentina (+54)">Argentina (+54)</option>
                                    <option value="Armenia (+374)">Armenia (+374)</option>
                                    <option value="Aruba (+297)">Aruba (+297)</option>
                                    <option value="Australia (+61)">Australia (+61)</option>
                                    <option value="Austria (+43)">Austria (+43)</option>
                                    <option value="Azerbaijan (+994)">Azerbaijan (+994)</option>
                                    <option value="Bahamas (+1-242)">Bahamas (+1-242)</option>
                                    <option value="Bahrain (+973)">Bahrain (+973)</option>
                                    <option value="Bangladesh (+880)">Bangladesh (+880)</option>
                                    <option value="Barbados (+1-246)">Barbados (+1-246)</option>
                                    <option value="Belarus (+375)">Belarus (+375)</option>
                                    <option value="Belgium (+32)">Belgium (+32)</option>
                                    <option value="Belize (+501)">Belize (+501)</option>
                                    <option value="Benin (+229)">Benin (+229)</option>
                                    <option value="Bermuda (+1-441)">Bermuda (+1-441)</option>
                                    <option value="Bhutan (+975)">Bhutan (+975)</option>
                                    <option value="Bolivia (+591)">Bolivia (+591)</option>
                                    <option value="Bosnia and Herzegovina (+387)">Bosnia and Herzegovina (+387)</option>
                                    <option value="Botswana (+267)">Botswana (+267)</option>
                                    <option value="Brazil (+55)">Brazil (+55)</option>
                                    <option value="British Indian Ocean Territory (+246)">British Indian Ocean Territory (+246)</option>
                                    <option value="Brunei Darussalam (+673)">Brunei Darussalam (+673)</option>
                                    <option value="Bulgaria (+359)">Bulgaria (+359)</option>
                                    <option value="Burkina Faso (+226)">Burkina Faso (+226)</option>
                                    <option value="Burundi (+257)">Burundi (+257)</option>
                                    <option value="Cambodia (+855)">Cambodia (+855)</option>
                                    <option value="Cameroon (+237)">Cameroon (+237)</option>
                                    <option value="Canada (+1)">Canada (+1)</option>
                                    <option value="Cape Verde (+238)">Cape Verde (+238)</option>
                                    <option value="Cayman Islands (+1-345)">Cayman Islands (+1-345)</option>
                                    <option value="Central African Republic (+236)">Central African Republic (+236)</option>
                                    <option value="Chad (+235)">Chad (+235)</option>
                                    <option value="Chile (+56)">Chile (+56)</option>
                                    <option value="China (+86)">China (+86)</option>
                                    <option value="Christmas Island (+61)">Christmas Island (+61)</option>
                                    <option value="Cocos (Keeling) Islands (+61)">Cocos (Keeling) Islands (+61)</option>
                                    <option value="Colombia (+57)">Colombia (+57)</option>
                                    <option value="Comoros (+269)">Comoros (+269)</option>
                                    <option value="Congo (+242)">Congo (+242)</option>
                                    <option value="Congo, Democratic Republic of the Congo (+243)">Congo, Democratic Republic of the Congo (+243)</option>
                                    <option value="Cook Islands (+682)">Cook Islands (+682)</option>
                                    <option value="Costa Rica (+506)">Costa Rica (+506)</option>
                                    <option value="Cote d'Ivoire (+225)">Cote d'Ivoire (+225)</option>
                                    <option value="Croatia (+385)">Croatia (+385)</option>
                                    <option value="Cuba (+53)">Cuba (+53)</option>
                                    <option value="Cyprus (+357)">Cyprus (+357)</option>
                                    <option value="Czech Republic (+420)">Czech Republic (+420)</option>
                                    <option value="Denmark (+45)">Denmark (+45)</option>
                                    <option value="Djibouti (+253)">Djibouti (+253)</option>
                                    <option value="Dominica (+1-767)">Dominica (+1-767)</option>
                                    <option value="Dominican Republic (+1-809)">Dominican Republic (+1-809)</option>
                                    <option value="East Timor (+670)">East Timor (+670)</option>
                                    <option value="Ecuador (+593)">Ecuador (+593)</option>
                                    <option value="Egypt (+20)">Egypt (+20)</option>
                                    <option value="El Salvador (+503)">El Salvador (+503)</option>
                                    <option value="Equatorial Guinea (+240)">Equatorial Guinea (+240)</option>
                                    <option value="Eritrea (+291)">Eritrea (+291)</option>
                                    <option value="Estonia (+372)">Estonia (+372)</option>
                                    <option value="Ethiopia (+251)">Ethiopia (+251)</option>
                                    <option value="Falkland Islands (Malvinas) (+500)">Falkland Islands (Malvinas) (+500)</option>
                                    <option value="Faroe Islands (+298)">Faroe Islands (+298)</option>
                                    <option value="Fiji (+679)">Fiji (+679)</option>
                                    <option value="Finland (+358)">Finland (+358)</option>
                                    <option value="France (+33)">France (+33)</option>
                                    <option value="French Guiana (+594)">French Guiana (+594)</option>
                                    <option value="French Polynesia (+689)">French Polynesia (+689)</option>
                                    <option value="French Southern Territories (+262)">French Southern Territories (+262)</option>
                                    <option value="Gabon (+241)">Gabon (+241)</option>
                                    <option value="Gambia (+220)">Gambia (+220)</option>
                                    <option value="Georgia (+995)">Georgia (+995)</option>
                                    <option value="Germany (+49)">Germany (+49)</option>
                                    <option value="Ghana (+233)">Ghana (+233)</option>
                                    <option value="Gibraltar (+350)">Gibraltar (+350)</option>
                                    <option value="Greece (+30)">Greece (+30)</option>
                                    <option value="Greenland (+299)">Greenland (+299)</option>
                                    <option value="Grenada (+1-473)">Grenada (+1-473)</option>
                                    <option value="Guadeloupe (+590)">Guadeloupe (+590)</option>
                                    <option value="Guam (+1-671)">Guam (+1-671)</option>
                                    <option value="Guatemala (+502)">Guatemala (+502)</option>
                                    <option value="Guinea (+224)">Guinea (+224)</option>
                                    <option value="Guinea-Bissau (+245)">Guinea-Bissau (+245)</option>
                                    <option value="Guyana (+592)">Guyana (+592)</option>
                                    <option value="Haiti (+509)">Haiti (+509)</option>                                    
                                    <option value="Honduras (+504)">Honduras (+504)</option>
                                    <option value="Hong Kong (+852)">Hong Kong (+852)</option>
                                    <option value="Hungary (+36)">Hungary (+36)</option>
                                    <option value="Iceland (+354)">Iceland (+354)</option>
                                    <option value="Indonesia (+62)">Indonesia (+62)</option>
                                    <option value="Iran (+98)">Iran (+98)</option>
                                    <option value="Iraq (+964)">Iraq (+964)</option>
                                    <option value="Ireland (+353)">Ireland (+353)</option>
                                    <option value="Israel (+972)">Israel (+972)</option>
                                    <option value="Italy (+39)">Italy (+39)</option>
                                    <option value="Jamaica (+1-876)">Jamaica (+1-876)</option>
                                    <option value="Japan (+81)">Japan (+81)</option>
                                    <option value="Jordan (+962)">Jordan (+962)</option>
                                    <option value="Kazakhstan (+7)">Kazakhstan (+7)</option>
                                    <option value="Kenya (+254)">Kenya (+254)</option>
                                    <option value="Kiribati (+686)">Kiribati (+686)</option>
                                    <option value="Kuwait (+965)">Kuwait (+965)</option>
                                    <option value="Kyrgyzstan (+996)">Kyrgyzstan (+996)</option>
                                    <option value="Laos (+856)">Laos (+856)</option>
                                    <option value="Latvia (+371)">Latvia (+371)</option>
                                    <option value="Lebanon (+961)">Lebanon (+961)</option>
                                    <option value="Lesotho (+266)">Lesotho (+266)</option>
                                    <option value="Liberia (+231)">Liberia (+231)</option>
                                    <option value="Libya (+218)">Libya (+218)</option>
                                    <option value="Liechtenstein (+423)">Liechtenstein (+423)</option>
                                    <option value="Lithuania (+370)">Lithuania (+370)</option>
                                    <option value="Luxembourg (+352)">Luxembourg (+352)</option>
                                    <option value="Macau (+853)">Macau (+853)</option>
                                    <option value="Madagascar (+261)">Madagascar (+261)</option>
                                    <option value="Malawi (+265)">Malawi (+265)</option>
                                    <option value="Malaysia (+60)">Malaysia (+60)</option>
                                    <option value="Maldives (+960)">Maldives (+960)</option>
                                    <option value="Mali (+223)">Mali (+223)</option>
                                    <option value="Malta (+356)">Malta (+356)</option>
                                    <option value="Marshall Islands (+692)">Marshall Islands (+692)</option>
                                    <option value="Martinique (+596)">Martinique (+596)</option>
                                    <option value="Mauritania (+222)">Mauritania (+222)</option>
                                    <option value="Mauritius (+230)">Mauritius (+230)</option>
                                    <option value="Mayotte (+262)">Mayotte (+262)</option>
                                    <option value="Mexico (+52)">Mexico (+52)</option>
                                    <option value="Micronesia (+691)">Micronesia (+691)</option>
                                    <option value="Moldova (+373)">Moldova (+373)</option>
                                    <option value="Monaco (+377)">Monaco (+377)</option>
                                    <option value="Mongolia (+976)">Mongolia (+976)</option>
                                    <option value="Montserrat (+1-664)">Montserrat (+1-664)</option>
                                    <option value="Morocco (+212)">Morocco (+212)</option>
                                    <option value="Mozambique (+258)">Mozambique (+258)</option>
                                    <option value="Myanmar (+95)">Myanmar (+95)</option>
                                    <option value="Namibia (+264)">Namibia (+264)</option>
                                    <option value="Nauru (+674)">Nauru (+674)</option>
                                    <option value="Nepal (+977)">Nepal (+977)</option>
                                    <option value="Netherlands (+31)">Netherlands (+31)</option>
                                    <option value="Netherlands Antilles (+599)">Netherlands Antilles (+599)</option>
                                    <option value="New Caledonia (+687)">New Caledonia (+687)</option>
                                    <option value="New Zealand (+64)">New Zealand (+64)</option>
                                    <option value="Nicaragua (+505)">Nicaragua (+505)</option>
                                    <option value="Niger (+227)">Niger (+227)</option>
                                    <option value="Nigeria (+234)">Nigeria (+234)</option>
                                    <option value="Niue (+683)">Niue (+683)</option>
                                    <option value="Norfolk Island (+672)">Norfolk Island (+672)</option>
                                    <option value="North Korea (+850)">North Korea (+850)</option>
                                    <option value="North Macedonia (+389)">North Macedonia (+389)</option>
                                    <option value="Northern Mariana Islands (+1-670)">Northern Mariana Islands (+1-670)</option>
                                    <option value="Norway (+47)">Norway (+47)</option>
                                    <option value="Oman (+968)">Oman (+968)</option>
                                    <option value="Pakistan (+92)">Pakistan (+92)</option>
                                    <option value="Palau (+680)">Palau (+680)</option>
                                    <option value="Panama (+507)">Panama (+507)</option>
                                    <option value="Papua New Guinea (+675)">Papua New Guinea (+675)</option>
                                    <option value="Paraguay (+595)">Paraguay (+595)</option>
                                    <option value="Peru (+51)">Peru (+51)</option>
                                    <option value="Philippines (+63)">Philippines (+63)</option>
                                    <option value="Pitcairn (+870)">Pitcairn (+870)</option>
                                    <option value="Poland (+48)">Poland (+48)</option>
                                    <option value="Portugal (+351)">Portugal (+351)</option>
                                    <option value="Puerto Rico (+1-787)">Puerto Rico (+1-787)</option>
                                    <option value="Qatar (+974)">Qatar (+974)</option>
                                    <option value="Reunion (+262)">Reunion (+262)</option>
                                    <option value="Romania (+40)">Romania (+40)</option>
                                    <option value="Russia (+7)">Russia (+7)</option>
                                    <option value="Rwanda (+250)">Rwanda (+250)</option>
                                    <option value="Saint Kitts and Nevis (+1-869)">Saint Kitts and Nevis (+1-869)</option> 
                                    <option value="Saint Lucia (+1-758)">Saint Lucia (+1-758)</option>
                                    <option value="Saint Vincent and the Grenadines (+1-784)">Saint Vincent and the Grenadines (+1-784)</option>
                                    <option value="Samoa (+685)">Samoa (+685)</option>
                                    <option value="San Marino (+378)">San Marino (+378)</option>
                                    <option value="Sao Tome and Principe (+239)">Sao Tome and Principe (+239)</option> 
                                    <option value="Saudi Arabia (+966)">Saudi Arabia (+966)</option>
                                    <option value="Senegal (+221)">Senegal (+221)</option>
                                    <option value="Seychelles (+248)">Seychelles (+248)</option>
                                    <option value="Sierra Leone (+232)">Sierra Leone (+232)</option>
                                    <option value="Singapore (+65)">Singapore (+65)</option>
                                    <option value="Slovakia (+421)">Slovakia (+421)</option>
                                    <option value="Slovenia (+386)">Slovenia (+386)</option>
                                    <option value="Solomon Islands (+677)">Solomon Islands (+677)</option>
                                    <option value="Somalia (+252)">Somalia (+252)</option>
                                    <option value="South Africa (+27)">South Africa (+27)</option>
                                    <option value="South Georgia and the South Sandwich Islands (+500)">South Georgia and the South Sandwich Islands (+500)</option>
                                    <option value="South Korea (+82)">South Korea (+82)</option>
                                    <option value="Spain (+34)">Spain (+34)</option>
                                    <option value="Sri Lanka (+94)">Sri Lanka (+94)</option>
                                    <option value="St. Helena (+290)">St. Helena (+290)</option>
                                    <option value="St. Pierre and Miquelon (+508)">St. Pierre and Miquelon (+508)</option>
                                    <option value="Sudan (+249)">Sudan (+249)</option>
                                    <option value="Suriname (+597)">Suriname (+597)</option>
                                    <option value="Svalbard and Jan Mayen Islands (+47)">Svalbard and Jan Mayen Islands (+47)</option>
                                    <option value="Swaziland (+268)">Swaziland (+268)</option>
                                    <option value="Sweden (+46)">Sweden (+46)</option>
                                    <option value="Switzerland (+41)">Switzerland (+41)</option>
                                    <option value="Syria (+963)">Syria (+963)</option>
                                    <option value="Taiwan (+886)">Taiwan (+886)</option>
                                    <option value="Tajikistan (+992)">Tajikistan (+992)</option>
                                    <option value="Tanzania (+255)">Tanzania (+255)</option>
                                    <option value="Thailand (+66)">Thailand (+66)</option>
                                    <option value="Togo (+228)">Togo (+228)</option>
                                    <option value="Tokelau (+690)">Tokelau (+690)</option>
                                    <option value="Tonga (+676)">Tonga (+676)</option>
                                    <option value="Trinidad and Tobago (+1-868)">Trinidad and Tobago (+1-868)</option>
                                    <option value="Tunisia (+216)">Tunisia (+216)</option>
                                    <option value="Turkey (+90)">Turkey (+90)</option>
                                    <option value="Turkmenistan (+993)">Turkmenistan (+993)</option>
                                    <option value="Turks and Caicos Islands (+1-649)">Turks and Caicos Islands (+1-649)</option>
                                    <option value="Tuvalu (+688)">Tuvalu (+688)</option>
                                    <option value="Uganda (+256)">Uganda (+256)</option>
                                    <option value="Ukraine (+380)">Ukraine (+380)</option>
                                    <option value="United Arab Emirates (+971)">United Arab Emirates (+971)</option>
                                    <option value="United Kingdom (+44)">United Kingdom (+44)</option>
                                    <option value="United States (+1)">United States (+1)</option>
                                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                                    <option value="Uruguay (+598)">Uruguay (+598)</option>
                                    <option value="Uzbekistan (+998)">Uzbekistan (+998)</option>
                                    <option value="Vanuatu (+678)">Vanuatu (+678)</option>
                                    <option value="Vatican City (+379)">Vatican City (+379)</option>
                                    <option value="Venezuela (+58)">Venezuela (+58)</option>
                                    <option value="Vietnam (+84)">Vietnam (+84)</option>
                                    <option value="Virgin Islands (British) (+1-284)">Virgin Islands (British) (+1-284)</option>
                                    <option value="Virgin Islands (U.S.) (+1-340)">Virgin Islands (U.S.) (+1-340)</option>
                                    <option value="Wallis and Futuna Islands (+681)">Wallis and Futuna Islands (+681)</option>
                                    <option value="Western Sahara (+212)">Western Sahara (+212)</option>
                                    <option value="Yemen (+967)">Yemen (+967)</option>
                                    <option value="Serbia (+381)">Serbia (+381)</option>
                                    <option value="Zambia (+260)">Zambia (+260)</option>
                                    <option value="Zimbabwe (+263)">Zimbabwe (+263)</option>
                                </select>
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="countryMsg">{countryMsg}</p>
                                </div>
                            </div>

                            
                            {/* Contact number */}
                            {/* <div className="form-group freelanceItem mb-md-0"> */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="contact" type="tel"  placeholder="Contact number*" pattern="[0-9]{10}" maxlength="10" required data-sb-validations="required" name="contact" value={contact} onChange={handleChange} ref={contactRef} />
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="contactMsg">{contactMsg}</p>
                                </div>
                            </div>

                            {/* Email address */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="email" type="email" placeholder="Email address*"  data-sb-validaions="required, email" required name="email" value={email} onChange={handleChange} ref={emailRef} />
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="emailMsg">{emailMsg}</p>
                                </div>
                            </div>

                            {/* DOB */}
                            <div className="form-group freelanceItem freelanceSmallItem">
                                {/* <label for="dob">Date of Birth:{" "}</label> */}
                                Date of Birth*:{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="date" id="dob" style={{height: "38px", borderRadius: "6px", cursor: "pointer", paddingLeft: "0.5%", paddingRight: "0.5%"}} name="dob" value={dob} onChange={handleChange} ref={dobRef} />
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="DOBMsg">{DOBMsg}</p>
                                </div>
                            </div>

                            {/* Photo */}
                            {/* <div className="form-group freelanceItem freelanceSmallItem" >
                                Photo* [.jpg or .jpeg within 35KB]:{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="file" id="photo" name="photo" onChange={handleChange} ref={photoRef} accept=".jpg, .jpeg" />
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="photoMsg">{photoMsg}</p>
                                </div>
                            </div> */}

                            {/* Resume */}
                            {/* <div className="form-group freelanceItem freelanceSmallItem" >
                                Resume* (.pdf):{" "}
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="file" id="resume" name="resume" onChange={handleChange} ref={resumeRef} accept=".pdf" />
                                

                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="resumeMsg">{resumeMsg}</p>
                                </div>
                            </div> */}

                            {/* Resume Link */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="resumeLink" type="text" placeholder="Resume drive link with photo*"  data-sb-validaions="required, resumeLink" required name="resumeLink" value={resumeLink} onChange={handleChange} ref={resumeRef} />
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="resumeMsg">{resumeMsg}</p>
                                </div>
                            </div>

                            
                            {/* Highest qualification */}
                            <div className="form-group freelanceItem">
                                {/* <label htmlFor="dropdown">Highest qualification:</label> */}
                                {/* Highest qualification:{" "} */}
                                <select 
                                    id="dropdown" 
                                    style={{
                                        width: "100%", 
                                        height: "38px", 
                                        borderRadius: "6px", 
                                        cursor: "pointer", 
                                        paddingLeft: "0.5%",
                                    }} 
                                    onfocus="this.size=10;" onblur="this.size=0;" onchange="this.size=1; this.blur();"
                                    name="qualification" 
                                    value={qualification} 
                                    onChange={handleChange} 
                                    ref={qualificationRef} 
                                >
                                    {/* <option value="">--Please choose an option--</option> */}
                                    <option value="">Highest qualification*</option>
                                    <option value="10th Standared">10th Standared</option>
                                    <option value="12th Standared">12th Standared</option>
                                    <option value="Graduation">Graduation</option>
                                    <option value="Post Graduation">Post Graduation</option>
                                    <option value="PhD">PhD</option>
                                </select>
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="qualificationMsg">{qualificationMsg}</p>
                                </div>
                            </div>

                            {/* Course Name */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="course" type="text" placeholder="Course name*"  data-sb-validaions="required, course" required name="course" value={course} onChange={handleChange} ref={courseRef} />
                            </div>

                            {/* Year of Passing */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="passingYear" type="tel"  placeholder="Year of passing*" pattern="[0-9]{4}" maxlength="4" required data-sb-validations="required" name="passingYear" value={passingYear} onChange={handleChange} ref={passingYearRef} />
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="passingYearMsg">{passingYearMsg}</p>
                                </div>
                            </div>

                            {/* Address */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="address" type="text" placeholder="Complete address*"  data-sb-validaions="required, address" required name="address" value={address} onChange={handleChange} ref={addressRef} />
                            </div>

                            {/* City */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="city" type="text" placeholder="City name*"  data-sb-validaions="required, city" required name="city" value={city} onChange={handleChange} ref={cityRef} />
                            </div>
                            
                            {/* Pin code */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="pinCode" type="tel"  placeholder="Pin code*" pattern="[0-9]{6}" maxlength="6" required data-sb-validations="required" name="pin" value={pin} onChange={handleChange} ref={pinRef} />
                                <div style={{textAlign: "left", marginLeft: "20px"}}>
                                    <p style={{"color": "#bf0a30"}} id="pinMsg">{pinMsg}</p>
                                </div>
                            </div>
                            
                            {/* Domain of Work */}
                            <div className="form-group freelanceItem freelanceSmallItem freelanceItemHead">
                                {/* <div>
                                    Project Details (Leave blank if fresher) :
                                </div> */}
                                Domain of work*:
                                <div className="col-md-6 freeconsultcheck" required>
                                    <label className="applicantcheckfont">
                                        <input style={{"transform": "scale(2)", color: "black", "margin-right": "15px", accentColor: '#e34a50'}}  type="checkbox" id="web" name="web" checked={web} onChange={handleChange} ref={workRef} />
                                        Website Developer
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </label>
                                    <label className="applicantcheckfont">
                                        <input style={{"transform": "scale(2)", "margin-right": "15px", accentColor: '#e34a50'}}  type="checkbox" id="mbapp" name="mbapp" checked={mbapp} onChange={handleChange} />
                                        Mobile App Developer
                                    </label>
                                    <label className="applicantcheckfont">
                                        <input style={{"transform": "scale(2)", "margin-right": "15px", accentColor: '#e34a50'}}  type="checkbox" id="mrkt" name="mrkt" checked={mrkt} onChange={handleChange} />
                                        Digital Marketer & SEO
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </label>
                                    <label className="applicantcheckfont">
                                        <input style={{"transform": "scale(2)", "margin-right": "15px", accentColor: '#e34a50'}}  type="checkbox" id="webgraph" name="webgraph" checked={webgraph} onChange={handleChange} />
                                        Web & Graphic Designer
                                    </label>
                                    <label className="applicantcheckfont">
                                        <input style={{"transform": "scale(2)", "margin-right": "15px", accentColor: '#e34a50'}}  type="checkbox" id="cntnt" name="cntnt" checked={cntnt} onChange={handleChange} />
                                        Content Writer
                                    </label>
                                </div>
                            </div>
                            {/* <div className="form-group freelanceItem freelanceWorkMsg" style={{textAlign: "left", marginLeft: "20px"}}> */}
                            <div className="form-group freelanceItem freelanceWorkMsg">
                                {/* <div style={{marginTop: "175px"}}> */}
                                    <p className="" style={{color: "#bf0a30"}} id="workMsg">{workMsg}</p>
                                {/* </div> */}
                            </div>

                            {/* Domain Work Description */}
                            {/* className="form-group freelanceItem-textarea" */}
                            {/* <div className="form-group freelanceItem mb-md-0 messageDiv"> */}
                            <div className="form-group freelanceItem" style={{marginTopTop: "20px"}}>
                                <textarea className="form-control" style={{height: "150px"}} id="description" placeholder="Describe your domain of work (optional)" name="describeDomain" value={describeDomain} onChange={handleChange} ></textarea>
                            </div>

                            {/* Project Details : */}
                            <div className="form-group freelanceItem freelanceSmallItem">
                                <div className="freelanceItemHead">
                                    Project URLs (Leave blank if fresher) :
                                </div>
                            </div>
                            {/* Project Link */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="projectURL" type="text"  placeholder="https://" name="project1" value={project1} onChange={handleChange} />
                            </div>
                            {/* Project Link */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="projectURL" type="text"  placeholder="https://" name="project2" value={project2} onChange={handleChange} />
                            </div>
                            {/* Project Link */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="projectURL" type="text"  placeholder="https://" name="project3" value={project3} onChange={handleChange} />
                            </div>
                            {/* Project Link */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="projectURL" type="text"  placeholder="https://" name="project4" value={project4} onChange={handleChange} />
                            </div>
                            {/* Project Link */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="projectURL" type="text"  placeholder="https://" name="project5" value={project5} onChange={handleChange} />
                            </div>


                            {/* Content Writing Demos */}
                            {/* <div className="form-group freelanceItem mb-md-0">
                                Sample Content Writing:{" "}
                                <input type="file" id="resume" name="filename" />
                            </div>
                            <div className="form-group freelanceItem mb-md-0">
                                Sample Content Writing:{" "}
                                <input type="file" id="resume" name="filename" />
                            </div>
                            <div className="form-group freelanceItem mb-md-0">
                                Sample Content Writing:{" "}
                                <input type="file" id="resume" name="filename" />
                            </div> */}

                            {/* Referral code */}
                            <div className="form-group freelanceItem">
                                <input className="form-control" id="referralCode" type="tel"  placeholder="Referral code (If any)" pattern="[0-9]{6}" maxlength="6" required data-sb-validations="required" name="refCode" value={refCode} onChange={handleChange} />
                            </div>

                            <br/>
                            {/* Declaration Check Box */}
                            <label className="declarationcheckfont">
                                <input style={{"transform": "scale(2)", color: "black", "margin-right": "15px", accentColor: '#e34a50'}}  type="checkbox" id="declaration" name="declaration" checked={declaration} onChange={handleChange} ref={declarationRef} />
                                I understand, accept and will abide by the 
                            </label>
                            <spam 
                                className="declarationcheckfont"
                                style={{color: "#bf0a30", cursor: "pointer", margin: 0, padding: 0}}
                                onClick={() => { window.open("/terms-and-conditions/", "_blank"); }}
                                // onClick={() => { window.location.href = "/templates/"; }}
                            >
                                Terms and Conditions
                            </spam>

                            <br/>
                            <br/>
                            
                            <div style={{color: '#bf0a30', fontWeight: 'bold', fontSize: '20px'}}>
                                {applicationSubmitionMsg}
                            </div>
                        </div>


                        {/* Right part */}

                    </div>
                    {/* Submit success message*/}
                    {/**/}
                    {/* This is what your users will see when the form*/}
                    {/* has successfully submitted*/}
                    <div className="d-none" id="submitSuccessMessage">
                        <div className="text-center text-white mb-3">
                            {/* <div className="fw-bolder">Form submission successful!</div> */}
                        
                            <br />
                            <a href="https://startbootstrap.com/solution/contact-forms">https://startbootstrap.com/solution/contact-forms</a>
                        </div>
                    </div>
                    

                    <div className="text-center">
                        <button 
                            style={{
                                backgroundColor: "#bf0a30", 
                                borderColor: "transparent", 
                                width: "170px", 
                                height: "50px", 
                                borderRadius: "10px"
                            }} 
                            className="btn btn-primary text-uppercase" 
                            id="formSubmitButton"
                            type="submit"
                            // disabled={formSubmitBtn}
                            onClick={freelanceApplicationSubmission}
                        >
                            Submit
                        </button>
                    </div>

                    
                </form>
            </div>
        </div>
    );
};

export default FreelancerCareerForm;